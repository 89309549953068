
import { defineComponent } from "vue";

export default defineComponent({
  name: "unknownDiamond",
  components: {},
  props: [],
  setup() {
    return {};
  },
});
