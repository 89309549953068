import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startAnimation && _ctx.startAnimation(...args))),
    class: "w-full"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageList, (imageIdx) => {
      return (_openBlock(), _createElementBlock("img", {
        key: imageIdx,
        id: 'animation-' + imageIdx,
        alt: "",
        src: 
        _ctx.Images.get(
          '/img/diamonds/rubin-diamond-' +
            _ctx.diamondForm.toLowerCase() +
            '-' +
            imageIdx +
            '.jpg'
        )
      ,
        class: _normalizeClass({
        block: parseInt(imageIdx) === _ctx.curImageIdx,
        hidden: parseInt(imageIdx) !== _ctx.curImageIdx,
      })
      }, null, 10, _hoisted_1))
    }), 128))
  ]))
}