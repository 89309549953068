
import { defineComponent } from "vue";
import Headline from "@/components/headline.vue";
import { Images } from "@/assets/ts/images";

export default defineComponent({
  name: "detailsColor",
  components: { Headline },
  props: ["color"],
  setup() {
    const colors: string[] = ["J", "I", "H", "G", "F", "E", "D"];
    return {
      colors,
      Images,
    };
  },
});
