<template>
  <div>
    <conf-item :conf-session="confSession" />
    <separation-line />
    <search-main :conf-session="confSession" />
  </div>
</template>

<script>
import SeparationLine from "@/components/separationLine";
import SearchMain from "@/components/searchMain.vue";
import { useRoute } from "vue-router";
import ConfItem from "@/components/confItem.vue";

export default {
  name: "ConfSearch",
  components: {
    ConfItem,
    SearchMain,
    SeparationLine,
  },
  setup() {
    const route = useRoute();
    const confSession = route.params.session;

    return {
      confSession,
    };
  },
};
</script>

<style></style>