import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "max-w-screen-lg mx-auto font-extralight" }
const _hoisted_2 = { class: "grid grid-cols-1 md:grid-cols-2 text-sm tracking-wider" }
const _hoisted_3 = { class: "font-bold pr-2" }
const _hoisted_4 = { class: "font-bold pr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_headline = _resolveComponent("headline")!
  const _component_full_specs_text = _resolveComponent("full-specs-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_headline, { class: "pt-0" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t(_ctx.pageStr + "title")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['type', 'form', 'size', 'color', 'clarity', 'cut'], (detail) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (
              typeof _ctx.diamond[detail] != 'undefined' &&
              _ctx.diamond[detail] !== '' &&
              _ctx.diamond[detail] !== 0
            )
              ? (_openBlock(), _createElementBlock("div", {
                  key: 'detail' + detail,
                  class: "odd:bg-brand-bg px-4 py-3 md:pl-12"
                }, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.pageStr + detail)) + ": ", 1),
                  _createVNode(_component_full_specs_text, {
                    diamond: _ctx.diamond,
                    detail: detail
                  }, null, 8, ["diamond", "detail"])
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 64))
      ]),
      _createElementVNode("div", null, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList([
            'polish',
            'symmetry',
            'fluorescence',
            'dimension',
            'institute',
          ], (detail) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (
              (typeof _ctx.diamond[detail] != 'undefined' &&
                _ctx.diamond[detail] !== '' &&
                _ctx.diamond[detail] !== 0) ||
              detail === 'ratio' ||
              detail === 'dimension'
            )
              ? (_openBlock(), _createElementBlock("div", {
                  key: 'detail' + detail,
                  class: "even:bg-brand-bg md:even:bg-white md:odd:bg-brand-bg px-4 md:px-0 py-3"
                }, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.pageStr + detail)) + ": ", 1),
                  _createVNode(_component_full_specs_text, {
                    diamond: _ctx.diamond,
                    detail: detail
                  }, null, 8, ["diamond", "detail"])
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 64))
      ])
    ])
  ]))
}