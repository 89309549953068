<template>
  <div
    v-if="modalVisible"
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="
        flex
        items-center
        justify-str
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
    >
      <div
        @click="closeModal()"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          sm:my-8 sm:align-middle sm:max-w-xl sm:w-full
        "
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div
            @click="closeModal()"
            class="absolute top-3 right-3 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
              <headline>
                {{ $t("details.nextStep.title") }}
              </headline>
              <div class="mt-2 mb-8">
                <p class="text-sm text-gray-500 text-center">
                  {{ $t("details.nextStep.text") }}
                </p>
              </div>
              <div class="mt-2 mb-8 px-10">
                <a
                  class="
                    uppercase
                    text-sm
                    bg-white
                    border border-brand-main
                    py-1
                    px-1
                    flex
                    items-center
                    justify-center
                    text-brand-main
                    hover:text-white hover:bg-brand-main
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-offset-gray-50
                    focus:ring-brand-main
                    cursor-pointer
                  "
                >
                  {{ $t("details.nextStep.button.addToRing") }}
                </a>
                <a
                  class="
                    mt-4
                    uppercase
                    text-sm
                    bg-white
                    border border-brand-main
                    py-1
                    px-1
                    flex
                    items-center
                    justify-center
                    text-brand-main
                    hover:text-white hover:bg-brand-main
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-offset-gray-50
                    focus:ring-brand-main
                    cursor-pointer
                  "
                >
                  {{ $t("details.nextStep.button.addToPendant") }}
                </a>
                <a
                  class="
                    mt-4
                    uppercase
                    text-sm
                    bg-white
                    border border-brand-main
                    py-1
                    px-1
                    flex
                    items-center
                    justify-center
                    text-brand-main
                    hover:text-white hover:bg-brand-main
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-offset-gray-50
                    focus:ring-brand-main
                    cursor-pointer
                  "
                >
                  {{ $t("details.nextStep.button.addToEaring") }}
                </a>

                <a
                  class="
                    mt-10
                    text-sm
                    py-1
                    px-1
                    flex
                    items-center
                    justify-center
                    text-brand-main
                    font-bold
                    cursor-pointer
                  "
                >
                  {{ $t("details.nextStep.button.onlyBuy") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headline from "@/components/headline";
export default {
  name: "SearchFormTypeSelectionInfo",
  components: { Headline },
  props: ["modalVisible", "diamond"],
  setup(props, { emit }) {
    const closeModal = () => {
      emit("update:modalVisible", false);
    };

    return { closeModal };
  },
};
</script>

<style></style>
