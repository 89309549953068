
import { defineComponent } from "vue";
import Headline from "@/components/headline.vue";
import { Images } from "@/assets/ts/images";

export default defineComponent({
  name: "detailsFluorescence",
  components: { Headline },
  props: ["fluorescence"],
  setup() {
    const fluorescences: string[] = ["F", "SL", "N"];
    return { fluorescences, Images };
  },
});
