
import { defineComponent } from "vue";
import { Images } from "@/assets/ts/images";

export default defineComponent({
  name: "buttons",
  props: ["values", "parameter", "type", "filterId"],
  components: {},
  setup(props, { emit }) {
    const toggleParameter = (selectedValue: string) => {
      emit("update:parameter", selectedValue);
    };

    const firstElement = Object.keys(props.values)[0];
    const lastElement = Object.keys(props.values)[
      Object.keys(props.values).length - 1
    ];

    return {
      toggleParameter,
      firstElement,
      lastElement,
      Images,
    };
  },
});
