import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "max-w-screen-lg mx-auto md:flex my-6 font-extralight" }
const _hoisted_2 = { class: "px-2 md:px-0 md:flex-1 flex flex-wrap justify-center md:order-last" }
const _hoisted_3 = ["src", "srcset", "alt"]
const _hoisted_4 = { class: "text-gray-400 text-sm text-center italic pb-2" }
const _hoisted_5 = { class: "px-8 md:px-0 md:flex-1 text-center md:text-right grid justify-items-center md:order-first" }
const _hoisted_6 = { class: "my-auto" }
const _hoisted_7 = { class: "md:pl-20 text-gray-400 text-sm whitespace-pre-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_headline = _resolveComponent("headline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fluorescences, (fluorescenceKey, fluorescenceIdx) => {
        return (_openBlock(), _createElementBlock("div", {
          key: fluorescenceIdx,
          class: "w-28 mx-2 md:mx-4 my-4 text-center flex items-center"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["p-2", {
            'border border-gray-400 rounded-md':
              fluorescenceKey === _ctx.fluorescence,
          }])
          }, [
            _createElementVNode("img", {
              src: 
              _ctx.Images.get(
                '/img/filter/fluorescence/' +
                  fluorescenceKey.toUpperCase() +
                  '@1x.jpg'
              )
            ,
              srcset: 
              _ctx.Images.get(
                '/img/filter/fluorescence/' +
                  fluorescenceKey.toUpperCase() +
                  '@2x.jpg'
              )
            ,
              class: "h-20 w-20 m-4 mx-auto rounded-md",
              alt: fluorescenceKey
            }, null, 8, _hoisted_3),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("details.fluorescence." + fluorescenceKey)), 1)
          ], 2)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_headline, { class: "md:text-right" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("details.fluorescence.title")) + ": ", 1)
          ]),
          _: 1
        }),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("details.fluorescence.text")), 1)
      ])
    ])
  ]))
}