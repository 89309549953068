
import { defineComponent } from "vue";

export default defineComponent({
  name: "FilterTitle",
  components: {},
  props: ["modalVisible", "filterIdx"],
  setup(props, { emit }) {
    const showModal = () => {
      emit("update:modalVisible", props["filterIdx"]);
    };

    const infoModals = [
      "type",
      "size",
      "color",
      "clarity",
      "cut",
      "fluorescence",
    ];
    return { showModal, infoModals };
  },
});
