export class Search {
  get(searchParameter: any) {
    return new Promise((resolve, reject) => {
      /**
       * ignore institute for lab grown diamonds
       */

      if (searchParameter.type === "lab") {
        searchParameter.institute = [];
      }

      /**
       *
       */
      fetch(process.env.VUE_APP_API_HOST + "/search", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(searchParameter),
      })
        .then((response) => response.json())
        .then((data) => {
          if (typeof data == "object") {
            resolve(data);
          } else {
            reject(data);
          }
        });
    });
  }

  quick(text: string) {
    return new Promise((resolve, reject) => {
      fetch(process.env.VUE_APP_API_HOST + "/search/quick", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: text,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (typeof data == "object") {
            resolve(data);
          } else {
            reject(data);
          }
        });
    });
  }
}
