<template>
  <tr
    class="
      table-row
      text-xs
      tracking-widest
      cursor-pointer
      group
      relative
      bg-white
      overflow-hidden
      text-center text-brand-text
      hover:bg-brand-main hover:text-white hover:font-bold
      align-middle
      even:bg-gray-50
      odd:bg-white
    "
  >
    <!-- picture -->
    <td class="12 text-center py-2">
      <div class="inline-block">
        <img
          v-if="panchImageStill"
          :src="panchImageStill"
          alt=""
          class="h-10"
        />
        <div v-else class="relative">
          <img
            :src="
              Images.get('/img/cut_' + diamond.form.toLowerCase() + '@1x.jpg')
            "
            :srcset="
              Images.get('/img/cut_' + diamond.form.toLowerCase() + '@2x.jpg')
            "
            alt=""
            class="h-10"
          />
        </div>
      </div>
    </td>
    <!-- parameter -->
    <td>
      {{ $t("details.form." + diamond.form) }}
    </td>
    <td>{{ $n(diamond.size, "carat") }} ct.</td>
    <td>
      {{ diamond.color }}
    </td>
    <td>
      {{ diamond.clarity }}
    </td>
    <td>
      <template v-if="diamond.form == 'BR'">
        {{ diamond.cut }}
      </template>
    </td>
    <td>
      {{ diamond.polish }}
    </td>
    <td>
      {{ diamond.symmetry }}
    </td>
    <td>
      {{ diamond.fluorescence }}
    </td>
    <td>
      {{ diamond.institute }}
    </td>
    <td class="font-bold" :class="{ 'text-red-600': diamond.special }">
      {{ $n(diamond.price, "currency") }}
    </td>
    <td class="flex space-x-2 items-center h-14">
      <router-link
        :to="{
          name: 'Diamond',
          params: {
            diamond: diamond.link,
          },
        }"
        class="
          uppercase
          text-xs
          bg-white
          border border-brand-main
          p-1
          flex-1
          items-center
          justify-center
          text-brand-main
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-offset-gray-50
          focus:ring-brand-main
          whitespace-nowrap
        "
      >
        {{ $t("searchResult.table.body.moreDetails") }}
      </router-link>
      <router-link
        :to="{
          name: 'Diamond',
          params: {
            diamond: diamond.link,
          },
        }"
        class="
          uppercase
          text-xs
          bg-white
          border border-brand-main
          p-1
          flex-1
          items-center
          justify-center
          text-brand-main
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-offset-gray-50
          focus:ring-brand-main
          whitespace-nowrap
        "
      >
        {{ $t("searchResult.table.body.select") }}
      </router-link>
    </td>
  </tr>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { PanchImages } from "@/assets/ts/PanchImages";
import { Images } from "@/assets/ts/images";

export default {
  name: "SearchResultTableLine",
  props: ["diamond"],
  components: {},
  setup(props) {
    /**
     * Favorites
     */

    const store = useStore();

    const toggleFavorite = (certificate) => {
      store.commit("favorites/toggle", certificate);
    };

    const favorites = computed(() => {
      return store.getters["favorites/getList"];
    });

    /**
     * Real picture
     */

    const panchImageStill = ref("");

    if (props.diamond.institute === "GIA") {
      new PanchImages().getStill(props.diamond.certificate).then((url) => {
        panchImageStill.value = url;
      });
    }

    return {
      toggleFavorite,
      panchImageStill,
      favorites,
      Images,
    };
  },
};
</script>

<style></style>
