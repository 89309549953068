<template>
  <div class="max-w-screen-xl mx-auto mt-4 mb-10">
    <div class="bg-white">
      <div class="max-w-2xl mx-auto px-4 py-8 sm:px-6 lg:max-w-7xl lg:px-8">
        <div
          v-if="favorites.length > 0"
          class="
            grid grid-cols-1
            gap-y-4
            sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10
            lg:grid-cols-3 lg:gap-x-8
          "
        >
          <template v-for="(certificate, diamondIdx) in favorites">
            <SearchResultDiamond
              :key="'diamond' + diamondIdx"
              v-if="diamondData[certificate]"
              :diamond="diamondData[certificate]"
            />
          </template>
        </div>
        <div v-else>
          <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="text-center">
            <svg
              class="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
            <h3 class="mt-2 text-sm font-medium text-gray-900">No favorites</h3>
            <p class="mt-1 text-sm text-gray-500">
              You haven't selected any diamonds.
            </p>
            <div class="mt-6">
              <router-link
                :to="{ name: 'Search' }"
                @click="showSearch = false"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-brand-main
                  hover:bg-brand-light hover:text-gray-800
                  focus:outline-none focus:ring-0
                "
              >
                <!-- Heroicon name: solid/plus -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="-ml-1 mr-2 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
                Start diamond search
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, watch, ref } from "vue";
import SearchResultDiamond from "@/components/search/result/diamond";
import { Diamond } from "@/assets/ts/diamond";

export default {
  name: "Favorites",
  components: {
    SearchResultDiamond,
  },
  setup() {
    const store = useStore();
    const diamondData = ref({});

    const favorites = computed(() => {
      return store.getters["favorites/getList"];
    });

    const loadDiamondData = () => {
      const DiamondObj = new Diamond();
      for (let diamondIdx in favorites.value) {
        store.commit("loading/actionStarted");
        DiamondObj.get(favorites.value[diamondIdx]).then((data) => {
          diamondData.value[data.link.toUpperCase()] = data;
          store.commit("loading/actionFinished");
        });
      }
    };

    watch(
      () => favorites.value,
      (current) => {
        loadDiamondData();
      },
      { deep: true }
    );

    loadDiamondData();

    return {
      favorites,
      diamondData,
    };
  },
};
</script>

<style></style>
