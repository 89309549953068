
import { defineComponent } from "vue";
import FullSpecsText from "@/components/details/fullSpecsText.vue";
import Headline from "@/components/headline.vue";

export default defineComponent({
  name: "fullSpecs",
  components: {
    Headline,
    FullSpecsText,
  },
  props: ["diamond"],
  setup() {
    const pageStr = "details.fullSpecs.";
    return {
      pageStr,
    };
  },
});
