import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-wrap gap-2 justify-items-start" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "block overflow-hidden text-center w-full w-12 text-xxs text-brand-main font-lighter italic py-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (valueText, valueIdx) => {
      return (_openBlock(), _createElementBlock("li", {
        key: 'value' + valueIdx
      }, [
        _createElementVNode("button", {
          type: "button",
          onClick: ($event: any) => (_ctx.toggleParameter(valueIdx))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["border border-brand-main flex rounded-md", {
            '': !_ctx.parameter.includes(valueIdx),
            'bg-brand-light': _ctx.parameter.includes(valueIdx),
            'w-20 h-20': Object.values(_ctx.values).length < 4,
            'w-14 h-14': Object.values(_ctx.values).length >= 4,
          }])
          }, [
            (_ctx.type === 'icon')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: 
              _ctx.Images.get(
                '/img/filter/' +
                  _ctx.filterId +
                  '/' +
                  valueIdx.toLowerCase() +
                  '.svg'
              )
            ,
                  class: _normalizeClass(["my-auto mx-auto", {
              'w-16 h-16': Object.values(_ctx.values).length < 4,
              'w-10, h-10': Object.values(_ctx.values).length >= 4,
            }]),
                  alt: valueIdx.toLowerCase()
                }, null, 10, _hoisted_3))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("span", _hoisted_4, _toDisplayString(valueText), 1)
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}