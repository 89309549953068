import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Search from "../views/search.vue";
import ConfSearch from "@/views/conf/confSearch.vue";
import Favorites from "../views/favorites.vue";
import Diamond from "../views/diamond.vue";
import ConfDiamond from "@/views/conf/confDiamond.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Search",
    component: Search,
  },
  {
    path: "/conf/:session",
    name: "ConfSearch",
    component: ConfSearch,
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: Favorites,
  },
  {
    path: "/diamond/:diamond",
    name: "Diamond",
    component: Diamond,
  },
  {
    path: "/conf/:session/diamond/:diamond",
    name: "ConfDiamond",
    component: ConfDiamond,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
