
import { ref, defineComponent, computed, onMounted, onUnmounted } from "vue";

import { Images } from "@/assets/ts/images";

export default defineComponent({
  name: "diamond360Defaults",
  components: {},
  props: ["diamondForm"],
  setup() {
    const imageFirstId = 1;
    const imageLastId = 30;
    const curImageIdx = ref(imageFirstId);

    const imageList = computed(() => {
      const imageList: string[] = [];

      for (let imageIdx = imageFirstId; imageIdx <= imageLastId; imageIdx++) {
        imageList.push(imageIdx.toString().padStart(3, "0"));
      }

      return imageList;
    });

    const setCurrentImageIdx = () => {
      if (curImageIdx.value >= imageLastId) {
        curImageIdx.value = imageFirstId;
      } else {
        curImageIdx.value++;
      }
    };

    const intervalId = ref();
    const startAnimation = () => {
      if (intervalId.value) {
        clearInterval(intervalId.value);
        intervalId.value = false;
      } else {
        intervalId.value = setInterval(setCurrentImageIdx, 150);
      }
    };

    onMounted(() => {
      startAnimation();
    });

    onUnmounted(() => {
      clearInterval(intervalId.value);
      intervalId.value = false;
    });

    return { startAnimation, imageList, curImageIdx, Images };
  },
});
