
import { computed, defineComponent, ref, watch } from "vue";
import { PanchImages } from "@/assets/ts/PanchImages";
import { Images } from "@/assets/ts/images";

export default defineComponent({
  name: "detailsImage",
  components: {},
  props: [
    "diamondForm",
    "diamondInstitute",
    "diamondCertificate",
    "diamondImage",
    "showViewer",
  ],
  setup(props) {
    /**
     * Real picture
     */

    const panchImageStill = ref("" as string);
    const panch360Images = ref([] as string[]);
    const tmpImages = ref([] as string[]);

    for (let idx = 0; idx < 65; idx++) {
      panch360Images.value.push("");
    }

    const cur360Idx = ref(0);

    const getImage = computed(() => {
      if (props.diamondImage.includes(".jpg")) {
        return props.diamondImage;
      } else {
        return "";
      }
    });

    const showNext360Idx = () => {
      do {
        cur360Idx.value = cur360Idx.value + 1;
        if (cur360Idx.value >= panch360Images.value.length) {
          cur360Idx.value = 0;
        }
      } while (panch360Images.value[cur360Idx.value] === "");

      if (panch360Images.value[cur360Idx.value] !== "") {
        panchImageStill.value =
          "data:image/jpeg;charset=utf-8;base64," +
          panch360Images.value[cur360Idx.value];
      }
    };

    const Panch = new PanchImages();

    if (
      (typeof props.diamondImage == "undefined" ||
        props.diamondImage == "" ||
        !props.diamondImage.includes(".jpg")) &&
      (props.diamondInstitute === "GIA" || props.diamondInstitute === "IGI")
    ) {
      Panch.getStill(props.diamondCertificate).then((url) => {
        panchImageStill.value = url as string;
      });
    }

    watch(
      () => props.diamondCertificate,
      () => {
        Panch.getStill(props.diamondCertificate).then((url) => {
          panchImageStill.value = url as string;
        });
      }
    );

    return {
      panchImageStill,
      panch360Images,
      tmpImages,
      Images,
      getImage,
    };
  },
});
