import { createStore } from "vuex";
import favorites from "./modules/favorites";
import searchParameter from "./modules/searchParameter";
import loading from "@/store/modules/loading";

export default createStore({
  modules: {
    favorites,
    searchParameter,
    loading,
  },
});
