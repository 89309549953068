
import { defineComponent } from "vue";
import Headline from "@/components/headline.vue";
import { Images } from "@/assets/ts/images";

export default defineComponent({
  name: "detailsCut",
  components: { Headline },
  props: ["cut"],
  setup() {
    const cuts: string[] = ["EX", "VG", "F", "P"];
    return {
      cuts,
      Images,
    };
  },
});
