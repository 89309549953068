
import { computed, defineComponent, ref } from "vue";
import ConfItemImage from "@/components/conf/confItemImage.vue";
import ConfItemDescriptionParts from "@/components/conf/description/confItemDescriptionParts.vue";

export default defineComponent({
  name: "confItem",
  components: { ConfItemDescriptionParts, ConfItemImage },
  props: ["confSession"],
  setup(props: any) {
    const item = ref({} as any);

    const showDetails = ref(false);

    const getItem = () => {
      fetch(
        process.env.VUE_APP_CONF_API_HOST +
          "/session/" +
          props["confSession"] +
          "/simple",
        {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response: any) => {
          return response.json();
        })
        .then((data: any) => {
          item.value = data.items[0];
        });
    };

    const itemImage = computed(() => {
      if (typeof item.value.image !== "undefined") {
        return item.value.image;
      } else {
        return {};
      }
    });

    getItem();

    return { item, itemImage, showDetails };
  },
});
