<template>
  <diamond-main />
</template>

<script>
import { defineComponent } from "vue";
import DiamondMain from "@/components/diamondMain.vue";

export default defineComponent({
  name: "Diamond",
  components: {
    DiamondMain,
  },
});
</script>

<style></style>
