export class Certificate {
  static getUrl(institute: string, certificate: string): string {
    if (institute === "IGI") {
      return (
        "https://www.igi.org/reports/verify-your-report?r=" +
        certificate.replace("-LG", "").replace("LG", "")
      );
    } else if (institute === "GIA") {
      return "https://www.gia.edu/report-check?reportno=" + certificate;
    } else if (institute === "HRD") {
      return "https://my.hrdantwerp.com/?record_number=" + certificate;
    } else {
      return "";
    }
  }
}
