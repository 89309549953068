export class Diamond {
  get(certificate: string) {
    return new Promise((resolve, reject) => {
      fetch(
        process.env.VUE_APP_API_HOST + "/diamond/" + certificate.toLowerCase(),
        {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status == 500) {
            reject(new Error("Unknown diamond"));
          }
          return response.json();
        })
        .then((data) => {
          if (typeof data == "object") {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch(() => {
          reject(new Error("Unknown diamond"));
        });
    });
  }
}
