
import { defineComponent } from "vue";
import { Images } from "@/assets/ts/images";

export default defineComponent({
  name: "separationLine",
  setup() {
    return {
      Images,
    };
  },
});
