<template>
  <search-main />
</template>

<script>
import SearchMain from "@/components/searchMain.vue";

export default {
  name: "Search",
  components: {
    SearchMain,
  },
};
</script>

<style></style>
