declare let rubinDiamondsAppUrl: string;

export class Images {
  static get(relativeUrl: string): string {
    relativeUrl = relativeUrl.trim();

    if (
      typeof rubinDiamondsAppUrl !== "undefined" &&
      rubinDiamondsAppUrl.trim() !== ""
    ) {
      if (
        rubinDiamondsAppUrl.charAt(rubinDiamondsAppUrl.length - 1) === "/" &&
        relativeUrl.charAt(0) === "/"
      ) {
        relativeUrl = relativeUrl.substring(1, relativeUrl.length);
      }

      return rubinDiamondsAppUrl.trim() + relativeUrl;
    } else {
      return relativeUrl;
    }
  }
}
