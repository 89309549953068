import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "w-full h-full flex justify-center relative",
  style: {"aspect-ratio":"1 / 1"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src", "srcset"]
const _hoisted_5 = { class: "absolute bottom-0 w-full text-center text-brand-text align-bottom bg-brand-light text-xs py-0.5 tracking-wider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.getImage != '')
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.getImage,
          alt: "",
          class: "w-full object-cover object-bottom"
        }, null, 8, _hoisted_2))
      : (_ctx.panchImageStill != '')
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.panchImageStill,
            alt: "",
            class: "w-full object-cover object-bottom"
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
    (_ctx.getImage == '' && _ctx.panchImageStill == '')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("img", {
            src: _ctx.Images.get('/img/cut_' + _ctx.diamondForm.toLowerCase() + '@1x.jpg'),
            srcset: 
          _ctx.Images.get('/img/cut_' + _ctx.diamondForm.toLowerCase() + '@2x.jpg')
        ,
            alt: "",
            class: "w-full object-cover object-center"
          }, null, 8, _hoisted_4),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("details.image.sampleImage")), 1)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}