import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";

import "./index.css";

const i18n = createI18n({
  locale: "de",
  fallbackLocale: "de",
  messages: {
    de: require("./locales/de.json"),
    en: require("./locales/de.json"),
  },
  numberFormats: {
    de: {
      currency: {
        style: "currency",
        currency: "EUR",
        notation: "standard",
        maximumFractionDigits: 0,
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      carat: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
  },
});

createApp(App).use(store).use(router).use(i18n).mount("#rubin_diamonds");
