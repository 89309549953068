
import DetailsImage from "@/components/details/image.vue";

export default {
  name: "detailsFullscreenImage",
  components: { DetailsImage },
  props: [
    "modalVisible",
    "diamondForm",
    "diamondInstitute",
    "diamondCertificate",
    "diamondImage",
  ],
  setup(props: string[], { emit }: { emit: any }) {
    const closeModal = () => {
      emit("update:modalVisible", false);
    };

    return { closeModal };
  },
};
