<template>
  <div
    v-if="modalVisible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="
        flex
        items-center
        justify-str
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
    >
      <div
        @click="closeModal()"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          sm:my-8 sm:align-middle sm:max-w-screen-md
          w-full
        "
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 absolute top-0 right-0 cursor-pointer"
                @click="closeModal()"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>

              <headline>
                {{ $t("searchForm.info." + infoType + ".title") }}
              </headline>
              <div class="mt-2 mb-8">
                <div v-if="infoImage.x1" class="w-full mb-2 mx-auto flex">
                  <img
                    :src="Images.get(infoImage.x1)"
                    alt=""
                    :srcset="Images.get(infoImage.x2)"
                    class="w-full"
                  />
                </div>
                <p class="text-sm text-gray-500 whitespace-pre-line">
                  {{ $t("searchForm.info." + infoType + ".text") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headline from "@/components/headline";
import { computed } from "vue";
import { Images } from "@/assets/ts/images";

export default {
  name: "SearchFormInfo",
  components: { Headline },
  props: ["modalVisible", "infoType"],
  setup(props, { emit }) {
    const closeModal = () => {
      emit("update:modalVisible", false);
    };

    const infoImage = computed(() => {
      switch (props["infoType"]) {
        case "cut":
          return {
            x1: "/img/details/cut@1x.png",
            x2: "/img/details/cut@2x.png",
          };
        case "size":
          return {
            x1: "/img/details/carat@1x.png",
            x2: "/img/details/carat@2x.png",
          };
        case "clarity":
          return {
            x1: "/img/details/clarity@1x.png",
            x2: "/img/details/clarity@2x.png",
          };
        case "color":
          return {
            x1: "/img/details/color@1x.png",
            x2: "/img/details/color@2x.png",
          };
        default:
          return "";
      }
    });

    return { closeModal, infoImage, Images };
  },
};
</script>

<style></style>
