import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mx-auto relative"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.form !== '')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", {
          class: "absolute text-sm font-extralight leading-10 inline-block",
          style: _normalizeStyle({
        left: _ctx.leftBottomMargin + Math.round(10 * _ctx.scaleFactor) + 'px',
        bottom:
          _ctx.leftBottomMargin + Math.round(_ctx.diamondLength * _ctx.pixelFactor) + 'px',
      })
        }, _toDisplayString(_ctx.$n(_ctx.diamondLength)) + "mm ", 5),
        _createElementVNode("p", {
          class: "absolute text-sm font-extralight leading-10 inline-block",
          style: _normalizeStyle({
        left:
          _ctx.leftBottomMargin +
          Math.round(_ctx.diamondWidth * _ctx.pixelFactor) +
          Math.round(30 * _ctx.scaleFactor) +
          'px',
        bottom: _ctx.leftBottomMargin + 'px',
      })
        }, _toDisplayString(_ctx.$n(_ctx.diamondWidth)) + "mm ", 5),
        _createElementVNode("img", {
          src: 
        _ctx.Images.get('/img/details/size/cut_' + _ctx.form.toLowerCase() + '@4x.png')
      ,
          alt: _ctx.form,
          class: "absolute",
          style: _normalizeStyle({
        left: _ctx.leftBottomMargin + 'px',
        bottom: _ctx.leftBottomMargin + 'px',
        width: Math.round(_ctx.diamondWidth * _ctx.pixelFactor) + 'px',
        height: Math.round(_ctx.diamondLength * _ctx.pixelFactor) + 'px',
      })
        }, null, 12, _hoisted_2),
        _createElementVNode("img", {
          src: _ctx.Images.get('/img/details/size/ruler.png'),
          style: _normalizeStyle({ width: _ctx.boxWidth + 'px' }),
          alt: "ruler"
        }, null, 12, _hoisted_3)
      ]))
    : _createCommentVNode("", true)
}