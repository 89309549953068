
import { defineComponent } from "vue";
import { Images } from "@/assets/ts/images";

export default defineComponent({
  name: "detailsCaratSize",
  props: ["form", "size", "diamondWidth", "diamondLength"],
  setup() {
    const rawPixelFactor = 12;
    const rawLeftBottomMargin = 120;
    const scaleFactor = 0.65;

    const pixelFactor = rawPixelFactor * scaleFactor;
    const leftBottomMargin = rawLeftBottomMargin * scaleFactor;

    const boxWidth = 400 * scaleFactor;

    return { boxWidth, scaleFactor, pixelFactor, leftBottomMargin, Images };
  },
});
