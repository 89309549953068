
import { defineComponent, ref } from "vue";
import { Images } from "@/assets/ts/images";

export default defineComponent({
  name: "imageList",
  props: ["values", "parameter", "type", "filterId", "showAll"],
  components: {},
  setup(props, { emit }) {
    const toggleParameter = (selectedValue: string) => {
      emit("update:parameter", selectedValue);
    };

    const showText = ref("");

    /**
     * Scroll
     */
    const lastElementNode = ref({} as HTMLElement);
    const firstElementNode = ref({} as HTMLElement);

    const scrollLast = () => {
      if (
        lastElementNode.value &&
        typeof lastElementNode.value.scrollIntoView === "function"
      ) {
        lastElementNode.value.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    };

    const scrollFirst = () => {
      if (
        firstElementNode.value &&
        typeof firstElementNode.value.scrollIntoView === "function"
      ) {
        firstElementNode.value.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    };

    return {
      lastElementNode,
      firstElementNode,
      scrollLast,
      scrollFirst,
      toggleParameter,
      Images,
      showText,
    };
  },
});
