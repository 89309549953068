
import { computed, defineComponent } from "vue";
import { Certificate } from "@/assets/ts/certificate";

export default defineComponent({
  name: "fullSpecsText",
  components: {},
  props: ["diamond", "detail"],
  setup(props) {
    const certificateUrl = computed(() => {
      return Certificate.getUrl(
        props.diamond.institute,
        props.diamond.certificate
      );
    });
    return {
      certificateUrl,
    };
  },
});
