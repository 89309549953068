
import { defineComponent } from "vue";
import Headline from "@/components/headline.vue";
import size from "@/components/details/carat/size.vue";

export default defineComponent({
  name: "detailsCarat",
  components: { Headline, size },
  props: ["carat", "form", "width", "length"],
  setup() {
    return {};
  },
});
