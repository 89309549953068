type favoritesType = {
  diamonds: string[];
};

const state = (): favoritesType => ({
  diamonds: [],
});

const getters = {
  getNumber(state: favoritesType): number {
    return state.diamonds.length;
  },
  getList(state: favoritesType): string[] {
    return state.diamonds;
  },
};

const actions = {
};

const mutations = {
  initialiseStore(state: favoritesType): void {
    if (localStorage.getItem("favorites-diamonds")) {
      state.diamonds = JSON.parse(
        localStorage.getItem("favorites-diamonds") + ""
      );
    }
  },
  toggle(state: favoritesType, certificate: string): void {
    certificate = certificate.toUpperCase();
    if (state.diamonds.includes(certificate)) {
      state.diamonds.splice(state.diamonds.indexOf(certificate), 1);
    } else {
      state.diamonds.push(certificate);
    }

    localStorage.setItem("favorites-diamonds", JSON.stringify(state.diamonds));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
