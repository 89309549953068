
import { defineComponent } from "vue";
import Headline from "@/components/headline.vue";
import { Images } from "@/assets/ts/images";

export default defineComponent({
  name: "detailsClarity",
  components: { Headline },
  props: ["clarity"],
  setup() {
    const clarities: string[] = [
      "SI2",
      "SI1",
      "VS2",
      "VS1",
      "VVS2",
      "VVS1",
      "IF",
      "FL",
    ];
    return { clarities, Images };
  },
});
