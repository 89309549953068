<template>
  <div class="group bg-white relative border border-brand-main border-solid">
    <!-- Wishlist -->
    <div
      v-if="false"
      class="absolute top-1 right-1 p-1 text-white hover:text-red-600 cursor-pointer z-30"
      :class="{
        'text-red-600': favorites.includes(diamond.link.toUpperCase()),
      }"
      @click="toggleFavorite(diamond.link)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8"
        :fill="favorites.includes(diamond.link) ? 'currentColor' : 'none'"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    </div>
    <!-- Diamond -->

    <icons-fast-delivery
      v-if="diamond.delivery_time < 2"
      class="absolute top-1 left-1 p-1 text-white hover:text-red-600 w-8 lg:w-10 z-30"
    ></icons-fast-delivery>

    <router-link
      :to="
        typeof confSession != 'undefined' && confSession !== ''
          ? {
              name: 'ConfDiamond',
              params: {
                diamond: diamond.link,
                session: confSession,
              },
            }
          : {
              name: 'Diamond',
              params: {
                diamond: diamond.link,
              },
            }
      "
      class="block"
    >
      <!-- Image -->
      <p
        class="group-hover:opacity-75 sm:aspect-none overflow-hidden flex object-center"
      >
        <details-image
          :diamond-form="diamond.form"
          :diamond-institute="diamond.institute"
          :diamond-certificate="diamond.certificate"
          :diamond-image="diamond.image_url || ''"
        />
      </p>
      <!-- Description -->
      <div class="p-2 pt-0">
        <div class="flex-grow w-full flex-row text-xs font-bold py-2">
          <span class="inline-block">{{ $n(diamond.size, "carat") }} ct.</span>
          <span class="text-brand-main mx-2 border-l border-brand-main"></span>
          <span class="inline-block">
            {{ $t("searchResult.body.diamond.type." + diamond.type) }}
          </span>
          <span class="text-brand-main mx-2 border-l border-brand-main"></span>
          <span class="inline-block md:">{{ diamond.institute }}</span>
        </div>
        <div>
          <div
            class="md:flex-shrink-0 text-xs tracking-wider space-x-2 text-left divide-brand-main"
          >
            <span class="inline-block">{{ diamond.color }}</span>
            <span class="text-brand-main text-center">|</span>
            <span class="inline-block">{{ diamond.clarity }}</span>
            <span class="text-brand-main text-center">|</span>
            <template v-if="diamond.form == 'BR'">
              <span class="inline-block">
                {{ diamond.cut }}
              </span>
              <span class="text-brand-main text-center">|</span>
            </template>
            <span class="inline-block">{{ diamond.polish }}</span>
            <template v-if="diamond.form == 'BR'">
              <span class="text-brand-main text-center">|</span>
            </template>
            <span class="inline-block">{{ diamond.symmetry }}</span>
            <span class="text-brand-main text-center">|</span>
            <span class="inline-block">{{ diamond.fluorescence }}</span>
          </div>
          <div
            class="md:flex-grow text-sm tracking-wider pt-2 font-bold text-left flex justify-start items-start"
            :class="{ 'text-red-600': diamond.special }"
          >
            <!--{{ diamond.certificate }}<br />-->
            {{ $n(diamond.price, "currency") }}
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import IconsFastDelivery from "@/components/icons/fastDelivery";
import DetailsImage from "@/components/details/image";

export default {
  name: "SearchResultDiamond",
  props: ["diamond", "confSession"],
  components: { DetailsImage, IconsFastDelivery },
  setup(props) {
    /**
     * Favorites
     */

    const store = useStore();

    const toggleFavorite = (certificate) => {
      store.commit("favorites/toggle", certificate);
    };

    const favorites = computed(() => {
      return store.getters["favorites/getList"];
    });

    return {
      toggleFavorite,
      favorites,
    };
  },
};
</script>

<style></style>
