
import Diamond360Defaults from "@/components/diamond360defaults.vue";

export default {
  name: "detailsFullscreenVideo",
  components: { Diamond360Defaults },
  props: ["modalVisible", "diamondUrl", "diamondForm"],
  setup(props: string[], { emit }: { emit: any }) {
    const closeModal = () => {
      emit("update:modalVisible", false);
    };
    console.log("start ani", props);

    return { closeModal };
  },
};
