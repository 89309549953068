import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-screen-lg mx-auto md:flex my-6 font-extralight" }
const _hoisted_2 = { class: "md:flex-1 md:order-last flex justify-items-center items-center" }
const _hoisted_3 = { class: "px-8 md:px-0 md:flex-1 text-center md:text-right md:order-first grid justify-items-center" }
const _hoisted_4 = { class: "my-auto" }
const _hoisted_5 = { class: "md:pl-8 text-gray-400 text-sm whitespace-pre-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_size = _resolveComponent("size")!
  const _component_headline = _resolveComponent("headline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_size, {
        carat: _ctx.carat,
        form: _ctx.form,
        diamondWidth: _ctx.width,
        diamondLength: _ctx.length
      }, null, 8, ["carat", "form", "diamondWidth", "diamondLength"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_headline, { class: "md:text-right" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("details.carat.title")) + ": " + _toDisplayString(_ctx.$n(_ctx.carat, "carat")) + " ct ", 1)
          ]),
          _: 1
        }),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("details.carat.text")), 1)
      ])
    ])
  ]))
}