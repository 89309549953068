
import { defineComponent } from "vue";
import { useStore } from "vuex";
import Loading from "@/components/loading.vue";

export default defineComponent({
  name: "App",
  components: {
    Loading,
  },
  setup() {
    const store = useStore();
    store.commit("favorites/initialiseStore");
  },
});
