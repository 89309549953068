
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { Images } from "@/assets/ts/images";
import VueSlider from "vue-3-slider-component";

export default defineComponent({
  name: "SearchFormStepSlider",
  props: ["values", "parameter", "type", "filterId", "bgColor", "details"],
  components: { VueSlider },
  setup(props) {
    const store = useStore();
    const valueKeys: string[] = Object.keys(props.values);
    const selectedValues = ref([valueKeys.length - 1, 0]);

    if (props.parameter.length > 0) {
      for (const idx in props.parameter) {
        const parameterIdx = valueKeys.indexOf(props.parameter[idx]);
        if (parameterIdx < selectedValues.value[0]) {
          selectedValues.value[0] = parameterIdx;
        }

        if (parameterIdx > selectedValues.value[1]) {
          selectedValues.value[1] = parameterIdx;
        }
      }
    } else {
      selectedValues.value = [0, valueKeys.length - 1];
    }

    const sliderData = computed(() => {
      const steps = [];
      let pos = 0;
      for (let idx in props.values) {
        steps.push({ value: pos, label: props.values[idx] });
        pos++;
      }
      return steps;
    });

    const toggleValue = (value: string) => {
      const parameterIdx = valueKeys.indexOf(value);

      if (parameterIdx < selectedValues.value[0]) {
        selectedValues.value[0] = parameterIdx;
      } else if (parameterIdx > selectedValues.value[1]) {
        selectedValues.value[1] = parameterIdx;
      } else if (
        selectedValues.value[0] < parameterIdx &&
        parameterIdx < selectedValues.value[1]
      ) {
        if (
          parameterIdx - selectedValues.value[0] <
          selectedValues.value[1] - parameterIdx
        ) {
          selectedValues.value[0] = parameterIdx;
        } else {
          selectedValues.value[1] = parameterIdx;
        }
      }

      commitSelectedValues();
    };

    watch(
      () => selectedValues.value,
      () => {
        commitSelectedValues();
      }
    );

    const commitSelectedValues = () => {
      let newParameters: string[] = [];

      for (const idx in valueKeys) {
        if (
          parseInt(idx) >= selectedValues.value[0] &&
          parseInt(idx) <= selectedValues.value[1]
        ) {
          newParameters.push(valueKeys[idx]);
        }
      }

      if (newParameters.length === valueKeys.length) {
        newParameters = [];
      }

      store.commit("searchParameter/setValuesList", {
        name: props.filterId,
        value: newParameters,
      });
    };

    /**
     * Details
     */

    const allowedDetails: string[] = ["color", "clarity"];

    const show = ref(props.details === true);

    const toggleDetails = (status: boolean) => {
      if (allowedDetails.includes(props.filterId)) {
        show.value =
          typeof props.details === "boolean" ? props.details : status;
      }
    };

    /**
     * Slider style
     */

    const sliderProcessStyle = {
      backgroundColor: "#A99D8B",
      height: "0.5em",
    };

    const sliderRailStyle = {
      backgroundColor: "white",
      border: "solid 1px #AAAAAA",
      height: "0.5em",
    };

    const sliderTooltipStyle = {
      backgroundColor: "#A99D8B",
      borderTopColor: "#A99D8B",
    };

    const sliderDotStyle = {
      backgroundColor: "#A99D8B",
      height: "0.9em",
      width: "0.9em",
      border: "solid 0.15em white",
      boxShadow: "none",
    };

    const sliderStepStyle = {
      display: "block",
      height: "0.45em",
      padding: "0",
      marginTop: "0em",
      width: "0.1em",
      backgroundColor: "#DDDDDD",
    };

    const sliderStepActiveStyle = {
      backgroundColor: "white",
      height: "1em",
      width: "0.2em",
      marginTop: "-0.2em",
    };

    /**
     *
     */

    return {
      selectedValues,
      toggleValue,
      valueKeys,
      show,
      toggleDetails,
      allowedDetails,
      Images,
      sliderData,
      sliderProcessStyle,
      sliderRailStyle,
      sliderTooltipStyle,
      sliderDotStyle,
      sliderStepStyle,
      sliderStepActiveStyle,
    };
  },
});
