<template>
  <conf-item :conf-session="confSession" />
  <separation-line />
  <diamond-main
    :conf-session="confSession"
    @update:addDiamond="addDiamond($event)"
  />
</template>

<script>
import { defineComponent } from "vue";
import DiamondMain from "@/components/diamondMain.vue";
import { useRoute } from "vue-router";
import ConfItem from "@/components/confItem.vue";
import SeparationLine from "@/components/separationLine.vue";

export default defineComponent({
  name: "ConfDiamond",
  components: {
    SeparationLine,
    ConfItem,
    DiamondMain,
  },
  setup() {
    const route = useRoute();
    const confSession = route.params.session;
    const diamond = route.params.diamond;

    const addDiamond = () => {
      fetch(process.env.VUE_APP_CONF_API_HOST + "/session/" + confSession, {
        method: "PATCH",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          item: 0,
          action: "diamond",
          value: diamond.toLowerCase(),
        }),
      }).then(() => {
        window.location.href = "/konfigurator/next/#/conf/" + confSession;
      });
    };
    return {
      addDiamond,
      confSession,
    };
  },
});
</script>

<style></style>
