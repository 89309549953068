export class PanchImages {
  getStill(certificate: string) {
    const url: string =
      "https://s3.ap-south-1.amazonaws.com/mediadiam/VideoNew/imaged/" +
      certificate +
      "/still.jpg";
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(url);
      };
      img.onerror = () => {
        resolve("");
      };
      img.src = url;
    });
  }

  get360(certificate: string) {
    const url: string =
      "https://s3.ap-south-1.amazonaws.com/mediadiam/VideoNew/Vision360.html?v=2&sv=0,1,2,3,4&z=1&btn=1,2,3,5?s=100&d=" +
      certificate;
    return url;
  }

  get360Images(certificate: string, fileIdx: number) {
    return new Promise((resolve, reject) => {
      const url: string =
        process.env.VUE_APP_API_HOST +
        "/diamond/" +
        certificate.toLowerCase() +
        "/images/360/" +
        fileIdx;
      fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        });
    });
  }
}
