<template>
  <section>
    <!-- background layer -->
    <div
      v-if="showSubmenu !== ''"
      @click="showSubmenu = ''"
      class="top-0 left-0 fixed opacity-50 bg-black w-full h-full z-50"
    />

    <!-- diamond type selection -->
    <search-form-info
      :info-type="infoModalVisible"
      :modal-visible="infoModalVisible != ''"
      @update:modalVisible="infoModalVisible = $event"
    />

    <div
      class="flex md:hidden uppercase text-brand-text font-light h-14 text-xs mb-4 tracking-wide whitespace-nowrap"
    >
      <div class="hidden md:block flex-1"><br /></div>
      <div class="flex-1">
        <a
          @click="toggleValue('type', 'earth')"
          class="cursor-pointer border border-brand-main w-full py-2 inline-block text-center"
          :class="{
            'bg-brand-main text-white': searchParameter['type'] === 'earth',
          }"
        >
          {{ $t("searchForm.typeSelection.buttons.earth") }}
        </a>
      </div>
      <div class="flex-1">
        <a
          @click="toggleValue('type', 'lab')"
          class="cursor-pointer border border-brand-main w-full py-2 inline-block text-center overflow-hidden"
          :class="{
            'bg-brand-main text-white': searchParameter['type'] === 'lab',
          }"
        >
          {{ $t("searchForm.typeSelection.buttons.lab") }}
        </a>
      </div>
      <div class="hidden md:block flex-1">
        <div
          class="text-brand-main ml-2 mt-1"
          @click="
            infoType = 'type';
            infoModalVisible = true;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>
    </div>
    <!-- Mobile -->

    <!-- Mobile - main menu -->
    <form class="lg:hidden">
      <ul class="grid grid-cols-4 gap-3 text-xs text-center">
        <li
          v-for="(filter, filterIdx) in filters"
          :key="'filter' + filterIdx"
          @click="showSubmenu = filterIdx"
          class="border border-2 px-2 py-1 shadow-sm rounded-md overflow-hidden bg-white"
        >
          <span class="tracking-wider uppercase">
            {{ $t("searchForm.parts." + filterIdx + ".title") }}
          </span>
          <span class="block text-xxs leading-5 text-gray-400">
            <span
              v-if="
                ((filterIdx === 'size' || filterIdx === 'price') &&
                  (searchParameter[filterIdx].from > 0.3 ||
                    (searchParameter[filterIdx].till > 0 &&
                      searchParameter[filterIdx].till !== 30))) ||
                searchParameter[filterIdx].length > 0 ||
                (filter.type === 'toggle' &&
                  searchParameter[filterIdx] === true)
              "
              class="text-brand-text"
            >
              {{ $t("searchForm.filtered") }}
            </span>
            <template v-else> {{ $t("searchForm.all") }} </template>
          </span>
        </li>
        <li
          @click="clear"
          class="border border-2 bg-gray-100 px-2 py-2 shadow-sm rounded-md overflow-hidden text-gray-400 uppercase text-xxs"
        >
          <span>{{ $t("searchForm.resetFilter") }}</span>
        </li>
      </ul>
    </form>
    <!-- Mobile - sub menu -->
    <transition name="slide-up">
      <div
        v-if="showSubmenu !== ''"
        class="lg:hidden fixed bottom-0 left-0 w-full px-0 py-0 z-50"
      >
        <div class="w-full h-full bg-white rounded-t-lg p-2 relative">
          <svg
            @click="showSubmenu = ''"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute top-4 right-4 h-7 w-7 text-gray-300 z-50"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            />
          </svg>
          <template
            v-for="(filter, filterIdx) in filters"
            :key="'filter' + filterIdx"
          >
            <fieldset v-if="showSubmenu === filterIdx" class="w-full">
              <div
                class="block pt-3 text-center font-medium text-gray-900 w-full cursor-pointer"
              >
                <headline class="mt-3 flex">
                  <span class="mx-auto flex items-center">
                    <span class="text-lg">{{
                      $t("searchForm.parts." + filterIdx + ".title")
                    }}</span>
                    <span
                      v-if="infoModals.includes(filterIdx)"
                      class="text-brand-main ml-2"
                      @click="
                        showSubmenu = '';
                        infoType = filterIdx;
                        infoModalVisible = filterIdx;
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                  </span>
                </headline>
                <p
                  v-if="false"
                  class="text-center font-extralight text-brand-text text-xs px-4 pb-4 h-24 tracking-wider"
                >
                  {{ $t("searchForm.parts." + filterIdx + ".desc") }}
                </p>
                <div
                  class="flex items-center justify-center justify-items-center w-full"
                >
                  <div v-if="filterIdx === 'size'" class="mb-6 mx-2 w-full">
                    <VueSlider
                      v-model="size"
                      :data="sizeSliderSteps"
                      :tooltips="true"
                      :hideLabel="true"
                      :lazy="true"
                      :processStyle="sliderProcessStyle"
                      :dotStyle="sliderDotStyle"
                      :railStyle="sliderRailStyle"
                      :tooltipStyle="sliderTooltipStyle"
                      :tooltipFormatter="(val) => $n(val, 'carat') + ' ct.'"
                      ref="sizeSliderMobile"
                    >
                      <template #step="{}">
                        <div class="hidden"></div>
                      </template>
                    </VueSlider>

                    <div class="flex w-full mt-4 text-xs text-brand-main">
                      <select
                        v-model="size[0]"
                        class="w-28 text-xs text-left h-8"
                      >
                        <option
                          v-for="(
                            curSizeFrom, curSizeFromIdx
                          ) in fromSizeSelectBox"
                          :value="curSizeFrom"
                          :key="'fromSize' + curSizeFromIdx"
                        >
                          {{ $n(curSizeFrom, "carat") }} ct.
                        </option>
                      </select>
                      <p class="flex-grow"></p>
                      <select
                        v-model="size[1]"
                        class="w-28 text-xs text-right h-8"
                      >
                        <option
                          v-for="(
                            curSizeTill, curSizeTillIdx
                          ) in tillSizeSelectBox"
                          :value="curSizeTill"
                          :key="'tillSize' + curSizeTillIdx"
                        >
                          {{ $n(curSizeTill, "carat") }} ct.
                        </option>
                      </select>
                    </div>
                  </div>
                  <div v-else-if="filterIdx === 'price'" class="mb-6 mx-2 w-full">
                    <VueSlider
                      v-model="price"
                      :data="priceSliderSteps"
                      :tooltips="true"
                      :hideLabel="true"
                      :lazy="true"
                      :processStyle="sliderProcessStyle"
                      :dotStyle="sliderDotStyle"
                      :railStyle="sliderRailStyle"
                      :tooltipStyle="sliderTooltipStyle"
                      :tooltipFormatter="(val) => $n(val, 'currency')"
                      ref="priceSliderMobile"
                    >
                      <template #step="{}">
                        <div class="hidden"></div>
                      </template>
                    </VueSlider>

                    <div class="flex w-full mt-2 text-xs text-brand-main">
                      <select
                        v-model="price[0]"
                        class="w-32 text-xs text-left h-8"
                      >
                        <option
                          v-for="(
                            curPriceFrom, curPriceFromIdx
                          ) in fromPriceSelectBox"
                          :value="curPriceFrom"
                          :key="'fromPrice' + curPriceFromIdx"
                        >
                          {{ $n(curPriceFrom, "currency") }}
                        </option>
                      </select>
                      <p class="flex-grow"></p>
                      <select
                        v-model="price[1]"
                        class="w-32 text-xs text-right h-8"
                      >
                        <option
                          v-for="(
                            curPriceTill, curPriceTillIdx
                          ) in tillPriceSelectBox"
                          :value="curPriceTill"
                          :key="'tillPrice' + curPriceTillIdx"
                        >
                          {{ $n(curPriceTill, "currency") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="mx-4 w-full mb-14 " v-else-if="filter.type === 'slider'">
                    <search-form-step-slider
                      :filterId="filterIdx"
                      :type="filter.type"
                      :values="filter.values"
                      :parameter="searchParameter[filterIdx]"
                      bg-color="bg-white"
                      :details="true"
                    />
                  </div>
                  <div class="mx-4 w-full" v-else-if="filterIdx === 'form'">
                    <image-list
                      filterId="form"
                      show-all="true"
                      :type="filters.form.type"
                      :values="filters.form.values"
                      :parameter="searchParameter.form"
                      @update:parameter="toggleValue('form', $event)"
                    />
                  </div>
                  <div v-else class="mx-4 mb-4">
                    <buttons
                      :filterId="filterIdx"
                      :type="filter.type"
                      :values="filter.values"
                      :parameter="searchParameter[filterIdx]"
                      @update:parameter="toggleValue(filterIdx, $event)"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </template>
          <div class="flex mb-4">
            <div class="flex">
              <svg
                @click="showSubmenu = showLastFilter"
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-brand-main mx-4 my-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </div>
            <div class="flex-grow text-center">
              <button
                @click="showSubmenu = ''"
                class="text-white bg-brand-main w-full p-2 tracking-wide font-light shadow-md uppercase text-xs"
              >
                {{ $tc("searchForm.showDiamonds") }}
              </button>
            </div>
            <div class="flex">
              <svg
                @click="showSubmenu = showNextFilter"
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-brand-main mx-4 my-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- Desktop -->
    <form class="hidden lg:block max-w-screen-lg mx-auto px-10">
      <div
        class="border-b border-brand-main flex w-full text-sm tracking-widest text-gray-500"
      >
        <div
          class="flex-1 text-center py-2 hover:bg-brand-main hover:text-white cursor-pointer"
          :class="{
            'text-brand-main font-bold': curFilter === 'formCaratPrice',
          }"
          @click="curFilter = 'formCaratPrice'"
        >
          {{ $t("searchForm.filter.formCaratPrice") }}
        </div>
        <div
          class="flex-1 text-center py-2 hover:bg-brand-main hover:text-white cursor-pointer"
          :class="{
            'text-brand-main font-bold': curFilter === 'colorClarityCut',
          }"
          @click="curFilter = 'colorClarityCut'"
        >
          <div class="border-l border-r border-brand-main">
            {{ $t("searchForm.filter.colorClarityCut") }}
          </div>
        </div>
        <div
          class="flex-1 text-center py-2 hover:bg-brand-main hover:text-white cursor-pointer"
          :class="{ 'text-brand-main font-bold': curFilter === 'moreFilter' }"
          @click="curFilter = 'moreFilter'"
        >
          {{ $t("searchForm.filter.moreFilter") }}
        </div>
      </div>
      <div class="w-full">
        <div v-if="curFilter === 'formCaratPrice'">
          <div class="grid grid-cols-2 gap-8 py-10">
            <div>
              <image-list
                filterId="form"
                :type="filters.form.type"
                :values="filters.form.values"
                :parameter="searchParameter.form"
                @update:parameter="toggleValue('form', $event)"
              />
            </div>
            <div class="pl-4 pr-10">
              <filter-title
                filter-idx="size"
                @update:modalVisible="infoModalVisible = $event"
              />
              <VueSlider
                v-model="size"
                :data="sizeSliderSteps"
                :tooltips="true"
                :hideLabel="true"
                :lazy="true"
                :processStyle="sliderProcessStyle"
                :dotStyle="sliderDotStyle"
                :railStyle="sliderRailStyle"
                :tooltipStyle="sliderTooltipStyle"
                :tooltipFormatter="(val) => $n(val, 'carat') + ' ct.'"
                ref="sizeSlider"
              >
                <template #step="{}">
                  <div class="hidden"></div>
                </template>
              </VueSlider>

              <div class="flex w-full mt-3 text-xs text-brand-main">
                <select
                  v-model="size[0]"
                  class="w-20 text-xs text-left h-6 p-1"
                >
                  <option
                    v-for="(curSizeFrom, curSizeFromIdx) in fromSizeSelectBox"
                    :value="curSizeFrom"
                    :key="'fromSize' + curSizeFromIdx"
                  >
                    {{ $n(curSizeFrom, "carat") }} ct.
                  </option>
                </select>
                <p class="flex-grow"></p>
                <select
                  v-model="size[1]"
                  class="w-20 text-xs text-left h-6 p-1"
                >
                  <option
                    v-for="(curSizeTill, curSizeTillIdx) in tillSizeSelectBox"
                    :value="curSizeTill"
                    :key="'tillSize' + curSizeTillIdx"
                  >
                    {{ $n(curSizeTill, "carat") }} ct.
                  </option>
                </select>
              </div>
            </div>
            <div class="flex items-center">
              <div
                class="flex w-full uppercase text-brand-text font-light text-xs tracking-wide whitespace-nowrap"
              >
                <div class="flex-1">
                  <a
                    @click="toggleValue('type', 'earth')"
                    class="cursor-pointer border border-brand-main w-full py-2 inline-block text-center"
                    :class="{
                      'bg-brand-main text-white':
                        searchParameter['type'] === 'earth',
                    }"
                  >
                    {{ $t("searchForm.typeSelection.buttons.earth") }}
                  </a>
                </div>
                <div class="flex-1">
                  <a
                    @click="toggleValue('type', 'lab')"
                    class="cursor-pointer border border-brand-main w-full py-2 inline-block text-center overflow-hidden"
                    :class="{
                      'bg-brand-main text-white':
                        searchParameter['type'] === 'lab',
                    }"
                  >
                    {{ $t("searchForm.typeSelection.buttons.lab") }}
                  </a>
                </div>
                <div class="flex-shrink">
                  <div
                    class="text-brand-main ml-2 mt-1"
                    @click="
                      infoType = 'type';
                      infoModalVisible = 'type';
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="pl-4 pr-10">
              <filter-title
                filter-idx="price"
                @update:modalVisible="infoModalVisible = $event"
              />
              <VueSlider
                v-model="price"
                :data="priceSliderSteps"
                :tooltips="true"
                :hideLabel="true"
                :lazy="true"
                :processStyle="sliderProcessStyle"
                :dotStyle="sliderDotStyle"
                :railStyle="sliderRailStyle"
                :tooltipStyle="sliderTooltipStyle"
                :tooltipFormatter="(val) => $n(val, 'currency')"
                ref="priceSlider"
              >
                <template #step="{}">
                  <div class="hidden"></div>
                </template>
              </VueSlider>

              <div class="flex w-full mt-3 text-xs text-brand-main">
                <select
                  v-model="price[0]"
                  class="w-24 text-xs text-left h-6 p-1"
                >
                  <option
                    v-for="(
                      curPriceFrom, curPriceFromIdx
                    ) in fromPriceSelectBox"
                    :value="curPriceFrom"
                    :key="'fromPrice' + curPriceFromIdx"
                  >
                    {{ $n(curPriceFrom, "currency") }}
                  </option>
                </select>
                <p class="flex-grow"></p>
                <select
                  v-model="price[1]"
                  class="w-24 text-xs text-left h-6 p-1"
                >
                  <option
                    v-for="(
                      curPriceTill, curPriceTillIdx
                    ) in tillPriceSelectBox"
                    :value="curPriceTill"
                    :key="'tillPrice' + curPriceTillIdx"
                  >
                    {{ $n(curPriceTill, "currency") }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="curFilter === 'colorClarityCut'">
          <div class="grid grid-cols-2 gap-8 p-10">
            <div class="px-6">
              <filter-title
                filter-idx="color"
                @update:modalVisible="infoModalVisible = $event"
              />
              <search-form-step-slider
                filterId="color"
                :type="filters.color.type"
                :values="filters.color.values"
                :parameter="searchParameter.color"
                bg-color="bg-gray-100"
                class="pt-3"
              />
            </div>
            <div class="px-6">
              <filter-title
                filter-idx="clarity"
                @update:modalVisible="infoModalVisible = $event"
              />
              <search-form-step-slider
                filterId="clarity"
                :type="filters.clarity.type"
                :values="filters.clarity.values"
                :parameter="searchParameter.clarity"
                bg-color="bg-gray-100"
                class="pt-3"
              />
            </div>
            <div class="px-6">
              <filter-title
                filter-idx="cut"
                @update:modalVisible="infoModalVisible = $event"
              />
              <search-form-step-slider
                filterId="cut"
                :type="filters.cut.type"
                :values="filters.cut.values"
                :parameter="searchParameter.cut"
                bg-color="bg-gray-100"
                class="pt-3"
              />
            </div>
          </div>
        </div>
        <div v-else-if="curFilter === 'moreFilter'">
          <div class="grid grid-cols-2 gap-8 p-10">
            <div class="px-6">
              <filter-title
                filter-idx="polish"
                @update:modalVisible="infoModalVisible = $event"
              />
              <search-form-step-slider
                filterId="polish"
                :type="filters.polish.type"
                :values="filters.polish.values"
                :parameter="searchParameter.polish"
                bg-color="bg-gray-100"
                class="pt-3"
              />
            </div>
            <div class="px-6">
              <filter-title
                filter-idx="fluorescence"
                @update:modalVisible="infoModalVisible = $event"
              />
              <search-form-step-slider
                filterId="fluorescence"
                :type="filters.fluorescence.type"
                :values="filters.fluorescence.values"
                :parameter="searchParameter.fluorescence"
                bg-color="bg-gray-100"
                class="pt-3"
              />
            </div>
            <div class="px-6">
              <filter-title
                filter-idx="symmetry"
                @update:modalVisible="infoModalVisible = $event"
              />
              <search-form-step-slider
                filterId="symmetry"
                :type="filters.symmetry.type"
                :values="filters.symmetry.values"
                :parameter="searchParameter.symmetry"
                bg-color="bg-gray-100"
                class="pt-3"
              />
            </div>
            <div v-if="filters.institute" class="px-6 mt-6 flex space-x-4">
              <filter-title
                filter-idx="institute"
                @update:modalVisible="infoModalVisible = $event"
              />
              <button-group
                filterId="institute"
                :type="filters.institute.type"
                :values="filters.institute.values"
                :parameter="searchParameter.institute"
                @update:parameter="toggleValue('institute', $event)"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { ref, defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";
import buttonGroup from "@/components/search/form/buttonGroup";
import SearchFormStepSlider from "@/components/search/form/stepSlider";
import Headline from "@/components/headline";
import Buttons from "@/components/search/form/buttons";
import SearchFormInfo from "@/components/search/info";
import FilterTitle from "@/components/search/form/filterTitle.vue";
import ImageList from "@/components/search/form/imageList.vue";
import VueSlider from "vue-3-slider-component";

export default defineComponent({
  name: "SearchForm",
  components: {
    ImageList,
    FilterTitle,
    SearchFormInfo,
    Buttons,
    Headline,
    SearchFormStepSlider,
    buttonGroup,
    VueSlider,
  },
  props: ["resultNumber"],
  setup() {
    const store = useStore();

    const searchParameter = computed(
      () => store.getters["searchParameter/get"]
    );

    /**
     * Submenu
     */

    const showSubmenu = ref("");

    /**
     * search parameter
     */

    const curSearchParameter = ref("");
    const curFilter = ref("formCaratPrice");
    const minSize = 0.3;
    const maxSize = 30.0;

    const size = ref([minSize, maxSize]);

    /**
     * Slider style
     */

    const sliderProcessStyle = {
      backgroundColor: "#A99D8B",
      height: "0.5em",
    };

    const sliderRailStyle = {
      backgroundColor: "white",
      border: "solid 1px #AAAAAA",
      height: "0.5em",
    };

    const sliderTooltipStyle = {
      backgroundColor: "#A99D8B",
      borderTopColor: "#A99D8B",
      fontSize: "0.8em",
    };

    const sliderDotStyle = {
      backgroundColor: "#A99D8B",
      height: "0.9em",
      width: "0.9em",
      border: "solid 0.15em white",
      boxShadow: "none",
    };

    /**
     *
     */

    let curSize = minSize;
    const sizeSlider = ref(null);
    const sizeSliderMobile = ref(null);

    const sizeSliderSteps = [{ value: curSize, label: curSize }];

    while (curSize < maxSize) {
      if (curSize < 1) {
        curSize += 0.02;
      } else if (curSize < 2) {
        curSize += 0.05;
      } else if (curSize < 3) {
        curSize += 0.1;
      } else if (curSize < 4) {
        curSize += 0.25;
      } else if (curSize < 10) {
        curSize += 0.5;
      } else if (curSize <= 20) {
        curSize += 5;
      } else {
        curSize += 10;
      }
      if (curSize > maxSize) {
        sizeSliderSteps.push({ value: maxSize, label: maxSize });
        break;
      } else {
        curSize = Math.round(curSize * 100) / 100;

        sizeSliderSteps.push({ value: curSize, label: curSize });
      }
    }

    /**
     *
     */

    const fromSizeSelectBox = computed(() => {
      let list = [];

      let pos = 0;

      while (sizeSliderSteps[pos].value < size.value[1]) {
        list.push(sizeSliderSteps[pos].value);
        pos++;
      }

      return list;
    });

    const tillSizeSelectBox = computed(() => {
      let list = [];

      let pos = 0;

      while (sizeSliderSteps[pos]) {
        if (sizeSliderSteps[pos].value > size.value[0]) {
          list.push(sizeSliderSteps[pos].value);
        }
        pos++;
      }

      return list;
    });

    /**
     * Price Slider
     */

    const minPrice = 0;
    const maxPrice = 5000000;

    const priceSlider = ref(null);
    const priceSliderMobile = ref(null);
    const price = ref([minPrice, maxPrice]);

    let curPrice = minPrice;
    const priceSliderSteps = [{ value: curPrice, label: curPrice }];

    while (curPrice <= maxPrice) {
      if (curPrice < 2000) {
        curPrice += 100;
      } else if (curPrice < 10000) {
        curPrice += 500;
      } else if (curPrice < 1000000) {
        curPrice += 10000;
      } else {
        curPrice += 250000;
      }

      if (curPrice > maxPrice) {
        priceSliderSteps.push({ value: maxPrice, label: maxPrice });
        break;
      } else {
        curPrice = Math.round(curPrice);
        priceSliderSteps.push({ value: curPrice, label: curPrice });
      }
    }

    const fromPriceSelectBox = computed(() => {
      let list = [];

      let pos = 0;

      while (priceSliderSteps[pos].value < price.value[1]) {
        list.push(priceSliderSteps[pos].value);
        pos++;
      }

      return list;
    });

    const tillPriceSelectBox = computed(() => {
      let list = [];

      let pos = 0;

      while (priceSliderSteps[pos]) {
        if (priceSliderSteps[pos].value > price.value[0]) {
          list.push(priceSliderSteps[pos].value);
        }
        pos++;
      }

      return list;
    });

    if (searchParameter.value.size.from >= minSize) {
      size.value[0] = searchParameter.value.size.from;
    }

    if (
      searchParameter.value.size.till > size.value[0] &&
      searchParameter.value.size.till <= maxSize
    ) {
      size.value[1] = searchParameter.value.size.till;
    }

    if (searchParameter.value.price.from >= minPrice) {
      price.value[0] = searchParameter.value.price.from;
    }

    if (
      searchParameter.value.price.till > size.value[0] &&
      searchParameter.value.price.till <= maxPrice
    ) {
      price.value[1] = searchParameter.value.price.till;
    }

    const toggleParameter = (parameter) => {
      curSearchParameter.value =
        curSearchParameter.value === parameter ? "" : parameter;
    };

    const toggleValue = (parameter, value) => {
      store.commit("searchParameter/toggleValue", {
        name: parameter,
        value: value,
      });
    };

    const clear = () => {
      store.commit("searchParameter/clear");
    };

    watch(
      () => price.value[0],
      () => {
        store.commit("searchParameter/setPrice", {
          from: price.value[0],
          till: price.value[1],
        });

        if (priceSlider.value) {
          priceSlider.value.setValue(price.value);
        }
        if (priceSliderMobile.value) {
          priceSliderMobile.value[0].setValue(price.value);
        }
      }
    );

    watch(
      () => price.value[1],
      () => {
        store.commit("searchParameter/setPrice", {
          from: price.value[0],
          till: price.value[1],
        });

        if (priceSlider.value) {
          priceSlider.value.setValue(price.value);
        }
        if (priceSliderMobile.value) {
          priceSliderMobile.value[0].setValue(price.value);
        }
      }
    );

    watch(
      () => size.value[0],
      () => {
        store.commit("searchParameter/setSize", {
          from: size.value[0],
          till: size.value[1],
        });

        if (sizeSlider.value) {
          sizeSlider.value.setValue(size.value);
        }
        if (sizeSliderMobile.value) {
          sizeSliderMobile.value[0].setValue(size.value);
        }
      }
    );

    watch(
      () => size.value[1],
      () => {
        store.commit("searchParameter/setSize", {
          from: size.value[0],
          till: size.value[1],
        });

        if (sizeSlider.value) {
          sizeSlider.value.setValue(size.value);
        }
        if (sizeSliderMobile.value) {
          sizeSliderMobile.value[0].setValue(size.value);
        }
      }
    );

    const filters = computed(() => {
      const curFilters = {
        form: {
          type: "icon",
          values: {
            BR: "Brilliant",
            OV: "Oval",
            EM: "Emerald",
            PE: "Pear",
            PR: "Princess",
            RA: "Radiant",
            HE: "Heart",
            MA: "Marquise",
            CU: "Cushion",
          },
        },
        size: {
          type: "text",
          values: {},
        },
        color: {
          type: "slider",
          values: {
            /*L: "L",*/
            /*K: "K",*/
            J: "J",
            I: "I",
            H: "H",
            G: "G",
            F: "F",
            E: "E",
            D: "D",
          },
        },
        clarity: {
          type: "slider",
          values: {
            /* I1: "I1", */
            SI2: "SI2",
            SI1: "SI1",
            VS2: "VS2",
            VS1: "VS1",
            VVS2: "VVS2",
            VVS1: "VVS1",
            IF: "IF",
            FL: "FL",
          },
        },
        cut: {
          type: "slider",
          values: {
            GD: "good",
            VG: "very good",
            EX: "excellent",
            ID: "ideal",
          },
        },
        polish: {
          type: "slider",
          values: {
            GD: "good",
            VG: "very good",
            EX: "excellent",
            /*ID: "Ideal",*/
          },
        },
        symmetry: {
          type: "slider",
          values: {
            GD: "good",
            VG: "very good",
            EX: "excellent",
          },
        },
        fluorescence: {
          type: "slider",
          values: {
            /*VS: "Very Strong",*/
            /*S: "Strong",*/
            /*M: "Medium",*/
            /*F: "Faint",*/
            SL: "Faint / Slight" /* Nutzt im Backend auch Faint */,
            VSL: "Very Slight",
            N: "None",
          },
        },
        price: {
          type: "text",
          values: {},
        },
      };
      if (searchParameter.value.type !== "lab") {
        curFilters.institute = {
          type: "icon",
          values: {
            GIA: "GIA",
            HRD: "HRD",
            IGI: "IGI",
          },
        };
      }

      return curFilters;
    });

    /**
     * filter
     */

    const showLastFilter = computed(() => {
      const filterKeys = Object.keys(filters.value);
      let lastFilter = filterKeys[filterKeys.length - 1];

      for (const idx in filterKeys) {
        if (filterKeys[idx] === showSubmenu.value) {
          return lastFilter;
        } else {
          lastFilter = filterKeys[idx];
        }
      }

      return lastFilter;
    });

    const showNextFilter = computed(() => {
      const filterKeys = Object.keys(filters.value);
      let lastFilter = "";

      for (const idx in filterKeys) {
        if (lastFilter === showSubmenu.value) {
          return filterKeys[idx];
        }

        lastFilter = filterKeys[idx];
      }

      return filterKeys[0];
    });

    /**
     * info modal
     */

    const infoModalVisible = ref(false);
    const infoType = ref("");
    const infoModals = [
      "type",
      "size",
      "color",
      "clarity",
      "cut",
      "fluorescence",
    ];
    return {
      curFilter,
      searchParameter,
      size,
      price,
      minPrice,
      maxPrice,
      minSize,
      maxSize,
      filters,
      curSearchParameter,
      toggleParameter,
      clear,
      toggleValue,
      showSubmenu,
      showLastFilter,
      showNextFilter,
      infoModalVisible,
      infoType,
      infoModals,
      fromSizeSelectBox,
      tillSizeSelectBox,
      fromPriceSelectBox,
      tillPriceSelectBox,
      sizeSliderSteps,
      sizeSlider,
      sizeSliderMobile,
      priceSlider,
      priceSliderMobile,
      priceSliderSteps,
      sliderProcessStyle,
      sliderRailStyle,
      sliderTooltipStyle,
      sliderDotStyle,
    };
  },
});
</script>
