<template>
  <div class="bg-white">
    <div class="max-w-screen-md mx-auto px-4 text-center">
      <Headline>{{ $t("steps.title." + searchParameter.type) }}</Headline>
    </div>

    <SearchForm
      :result-number="searchResultNumber"
      class="max-w-screen-xl mx-auto px-4"
    />
    <div class="max-w-2xl mx-auto pb-4 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <SearchResult
        id="search-result"
        class="lg:mt-0 lg:col-span-2 xl:col-span-3"
        :searchResult="searchResult"
        :conf-session="confSession"
      />
    </div>
    <separation-line />
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import SearchResult from "@/components/search/result";
import SearchForm from "@/components/search/form";
import { Search } from "@/assets/ts/search";
import { useStore } from "vuex";
import SeparationLine from "@/components/separationLine";
import Headline from "@/components/headline";

export default {
  name: "SearchMain",
  components: {
    SeparationLine,
    SearchForm,
    SearchResult,
    Headline,
  },
  props: ["confSession"],
  setup() {
    const router = useRouter();
    const curRoute = useRoute();
    const store = useStore();
    const showFilter = ref(false);

    const searchResult = ref([]);
    const searchResultNumber = ref(0);
    const inputWait = ref(false);

    const filters = {
      size: {
        name: "Size",
        type: "range",
      },
      form: {
        name: "Form",
        type: "list",
      },
      color: {
        name: "Color",
        type: "list",
      },
      clarity: {
        name: "Clarity",
        type: "list",
      },
      cut: {
        name: "Cut",
        type: "list",
      },
      polish: {
        name: "Polish",
        type: "list",
      },
      symmetry: {
        name: "Symmetry",
        type: "list",
      },
      fluorescence: {
        name: "Fluorescence",
        type: "list",
      },
      price: {
        name: "Price",
        type: "range",
      },
    };

    const searchParameter = computed(
      () => store.getters["searchParameter/get"]
    );

    const search = () => {
      store.commit("loading/actionStarted");
      console.log("Search Start");

      if (curRoute.name === "ConfSearch") {
        router.push({
          name: "ConfSearch",
          query: store.getters["searchParameter/getAsUri"],
        });
      } else {
        router.push({
          name: "Search",
          query: store.getters["searchParameter/getAsUri"],
        });
      }

      new Search().get(store.getters["searchParameter/get"]).then((data) => {
        if (data.page && data.page.current > 1 && searchResult.value.diamonds) {
          data.diamonds = searchResult.value.diamonds.concat(data.diamonds);
        }

        searchResult.value = data;

        if (searchResult.value.page) {
          searchResultNumber.value = searchResult.value.page.all;
        }

        store.commit("loading/actionFinished");
        inputWait.value = false;
      });
    };

    const setQuerySearchParameter = () => {
      console.log("start");
      const defaultSearchParameter =
        store.getters["searchParameter/getDefaultState"];

      let querySearchParameter = {
        type:
          curRoute.query.t === "lab"
            ? "lab"
            : "earth" || defaultSearchParameter.type,
        size: {
          from:
            parseFloat(curRoute.query.sf) || defaultSearchParameter.size.from,
          till:
            parseFloat(curRoute.query.st) || defaultSearchParameter.size.till,
        },
        form:
          typeof curRoute.query.fo === "string"
            ? curRoute.query.fo === ""
              ? []
              : curRoute.query.fo.split(",")
            : defaultSearchParameter.form,
        color:
          typeof curRoute.query.co === "string"
            ? curRoute.query.co === ""
              ? []
              : curRoute.query.co.split(",")
            : defaultSearchParameter.color,
        clarity:
          typeof curRoute.query.cl === "string"
            ? curRoute.query.cl === ""
              ? []
              : curRoute.query.cl.split(",")
            : defaultSearchParameter.clarity,
        cut:
          typeof curRoute.query.cu === "string"
            ? curRoute.query.cu === ""
              ? []
              : curRoute.query.cu.split(",")
            : defaultSearchParameter.cut,
        polish:
          typeof curRoute.query.po === "string"
            ? curRoute.query.po === ""
              ? []
              : curRoute.query.po.split(",")
            : defaultSearchParameter.polish,
        symmetry:
          typeof curRoute.query.sy === "string"
            ? curRoute.query.sy === ""
              ? []
              : curRoute.query.sy.split(",")
            : defaultSearchParameter.symmetry,
        fluorescence:
          typeof curRoute.query.fl === "string"
            ? curRoute.query.fl === ""
              ? []
              : curRoute.query.fl.split(",")
            : defaultSearchParameter.fluorescence,
        price: {
          from:
            parseFloat(curRoute.query.pf) || defaultSearchParameter.price.from,
          till:
            parseFloat(curRoute.query.pt) || defaultSearchParameter.price.till,
        },
        institute:
          typeof curRoute.query.in === "string"
            ? curRoute.query.in === ""
              ? []
              : curRoute.query.in.split(",")
            : defaultSearchParameter.institute,
        fastDelivery:
          curRoute.query.fd === "yes" || defaultSearchParameter.fastDelivery,
        page: {
          current: defaultSearchParameter.page.current,
          results: defaultSearchParameter.page.results,
        },
        order: {
          by: curRoute.query.b || defaultSearchParameter.order.by,
          sort: curRoute.query.s || defaultSearchParameter.order.sort,
        },
      };

      store.dispatch("searchParameter/setFullState", querySearchParameter);
    };

    watch(
      () => curRoute.query,
      () => {
        setQuerySearchParameter();
      }
    );

    watch(
      () => store.getters["searchParameter/get"],
      () => {
        if (curRoute.name === "Search" || curRoute.name === "ConfSearch") {
          if (!inputWait.value) {
            inputWait.value = true;
            setTimeout(search, 500);
          }
        }
      },
      { deep: true }
    );

    setQuerySearchParameter();

    return {
      showFilter,
      searchResult,
      searchResultNumber,
      search,
      filters,
      searchParameter,
    };
  },
};
</script>

<style></style>
